<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="-1" :dialogVisible2="topDialogVisible"></top>
      <div class="container-box bg-white flex-column justify-content-start align-items-center">
        <div class="width-100 flex-row justify-content-start align-items-center border-b">
          <p class="fs-super-big black fw-mid">需求详细</p>
        </div>
        <div class="main-box width-100">
          <div class="width-100 margin-b flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15">版权需求分类</p>
            <p class="fs-big black width-85">{{ info.copyrightTypeName }}</p>
          </div>
          <div class="width-100 margin-b flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15">版权名称</p>
            <p class="fs-big black width-85">{{ info.title }}</p>
          </div>
          <div class="width-100 margin-b flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15">版权用途</p>
            <p class="fs-big black width-85">{{ info.copyrightUsageName }}</p>
          </div>
          <div v-if="info.usageType !== '1'" class="width-100 margin-b flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15">企业名称</p>
            <p class="fs-big black width-85">{{ info.companyName }}</p>
          </div>
          <div v-else class="width-100 margin-b flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15">联系姓名</p>
            <p class="fs-big black width-85">{{ info.companyName }}</p>
          </div>
          <div v-if="info.picture" class="width-100 margin-b flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15">需求图片</p>
            <div class="width-85 flex-row justify-content-start align-items-star flex-wrap">
              <el-image class="picture" :src="item" v-for="(item, index) in info.picture" :key="index" fit="contain"></el-image>
            </div>
          </div>
          <div class="width-100 flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15">联系方式</p>
            <p class="fs-big black width-85">{{ info.phone }}</p>
          </div>
          <div class="width-100 margin-b flex-row justify-content-start align-items-star">
            <p class="fs-big darkgrey opacity-8 width-15"></p>
            <p v-if="loginFlag && showLook" class="fs-big black width-85"><el-button type="primary" plain round @click="lookClick">查看联系方式</el-button></p>
            <p v-if="!loginFlag" class="fs-big black width-85"><el-button type="primary" plain round @click="lookClick">登录查看联系方式</el-button></p>
          </div>
        </div>
      </div>
      <div class="container-box recommendation-box">
        <div class="width-100 margin-b flex-row justify-content-spaceBetween align-items-center">
          <p class="title">相关<span class="theme-blue">推荐</span></p>
          <p class="fs-big darkgrey opacity-8 pointer" @click="getCopyrightListData">换一批<i class="el-icon-refresh margin-l fs-big"></i></p>
        </div>
        <div class="width-100 right-box flex-row justify-content-spaceBetween align-items-star flex-wrap">
          <div
            class="item bg-white pointer"
            v-for="(item, index) in copyrightRegistrationList.slice(0, 4)"
            @click="$router.push({ path: '/copyrightDisplayDetail', query: { id: item.id } })"
            :key="index"
          >
            <van-image class="image" :src="item.picture" width="100%" fit="contain"></van-image>
            <p class="padding-Tb-10 padding-lr fs-big black width-100 cut-text name position-relative">{{ item.title }}</p>
            <p class="padding-lr fs-mid darkgrey opacity-8 width-100 cut-text margin-b">{{ item.copyrightOwnerName }}</p>
            <p class="padding-lr fs-big opacity-8 theme-blue width-100 cut-text margin-b">{{ item.copyrightPrize }}</p>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { getCopyrightDemandInfo, getCopyrightDemandDetailInfo } from '@/api/tranCopyrightDemand';
import { registrationList } from '@/api/tranCopyrightRegistration';
import * as _ from 'lodash';
import { getTextByValue } from '@/util/helper';
import { getDict } from '@/api/dict';

export default {
  name: 'demandDetail',
  components: {
    top,
    bottom
  },
  data() {
    return {
      topDialogVisible: false,
      showLook: true,
      id: this.$route.query.id,
      info: {},
      PROJECT_BASE_URL: process.env.VUE_APP_SERVE_PATH,
      copyrightRegistrationList: [],
      copyrightParams: {
        pageNum: 1,
        pageSize: 4
      },
      tranCopyrightUsageDist: [],
      params: {
        params: {
          contactMsg: true
        }
      },
      loginFlag: false
    };
  },
  async created() {
    await this.setDist();
    this.getCopyrightDemand(this.id);
    this.getCopyrightListData();
    if (localStorage.getItem('bqsb_login')) {
      this.loginFlag = true;
    }
  },
  methods: {
    async setDist() {
      return Promise.all([getDict('tran_copyright_usage')]).then((rows) => {
        this.tranCopyrightUsageDist = rows[0];
      });
    },
    async getCopyrightDemand(id) {
      const info = (await getCopyrightDemandInfo(id)).data;
      this.info = {
        ..._.pick(info, ['copyrightTypeName', 'companyName', 'title', 'phone', 'usageType']),
        picture: info.otherFiles
          ? info.otherFiles.split(',').map((url) => `${this.PROJECT_BASE_URL.substring(0, this.PROJECT_BASE_URL.length)}${url}`)
          : null,
        copyrightUsageName: getTextByValue(this.tranCopyrightUsageDist, info.copyrightUsage)
      };
      console.log('this.tranCopyrightUsageDist:', this.tranCopyrightUsageDist);
    },
    async getCopyrightDemandDetail() {
      const info = (await getCopyrightDemandDetailInfo(this.id, this.params)).data;
      this.info = {
        ...this.info,
        phone: info.phone
      };
      // console.log('this.tranCopyrightUsageDist:', this.tranCopyrightUsageDist);
    },
    async getCopyrightListData() {
      const copyrightRegistrationList = (await registrationList(this.copyrightParams)).rows;
      if (copyrightRegistrationList.length === 4) {
        this.copyrightRegistrationList = copyrightRegistrationList.map((row) => {
          return {
            picture: row.copyrightImage
              ? `${this.PROJECT_BASE_URL.substring(0, this.PROJECT_BASE_URL.length)}${row.copyrightImage.split(',')[0]}`
              : null,
            ..._.pick(row, ['id', 'title', 'copyrightOwnerName']),
            copyrightPrize: row.copyrightPriceType === '1' ? '价格面议' : row.copyrightPrize
          };
        });
        this.copyrightParams.pageNum++;
      } else {
        this.copyrightParams.pageNum = 1;
        this.getCopyrightListData();
      }
    },
    async lookClick() {
      if (localStorage.getItem('bqsb_login')) {
        // 登录存在
        await this.getCopyrightDemandDetail();
        this.showLook = false;
        // this.dialogVisible = true;
      }else {
        this.topDialogVisible = false;
        this.topDialogVisible = true;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    padding: 30px;
    border-radius: 10px;
    .main-box {
      padding: 60px 20px 0;

      .picture {
        max-width: max-content;
        height: 300px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      /deep/.el-button--primary.is-plain {
        background: rgba(0, 0, 0, 0);
        span {
          font-size: 18px;
        }
      }
      .el-button--primary.is-plain:hover {
        background: rgba(0, 0, 0, 0);
        color: #3f96ff;
      }
    }
  }
  .recommendation-box {
    .title,
    span {
      font-size: 36px;
      font-weight: bold;
    }
    .right-box {
      background: #f3f9ff;
      //height: 700px;
      border-radius: 10px;
      .item {
        width: 23%;
        border-radius: 10px;
        overflow: hidden;
        margin: 15px 0;
        height: 380px;
        box-shadow: 0 0 10px 0 rgba(133, 188, 240, 0.25);
        // .name {
        //   //left: -8px;
        // }
      }
      .image {
        height: 230px;
      }
    }
  }
}
</style>
