import request from '@/util/request';

export function postTranCopyrightDemand(data) {
    return request({
        url: `/tran/tranCopyrightDemand`,
        method: 'post',
        data
    });
}
export function putTranCopyrightDemand(data) {
    return request({
        url: `/tran/tranCopyrightDemand`,
        method: 'put',
        data
    });
}

export function getTranCopyrightDemandUnAuth(params) {
    return request({
        url: `/tran/tranCopyrightDemand/userUnAuth/list`,
        method: 'get',
        params: params
    });
}

export function getCopyrightDemandInfo(id, params) {
    return request({
        url: `/tran/tranCopyrightDemand/${id}`,
        method: 'get',
        params,
    });
}

export function getCopyrightDemandDetailInfo(id, params) {
    return request({
        url: `/tran/tranCopyrightDemand/${id}/detail`,
        method: 'get',
        params,
    });
}

export function getMyDemand(params) {
    return request({
        url: `/tran/tranCopyrightDemand/my/list`,
        method: 'get',
        params,
    });
}

export function deleteMyDemand(id) {
    return request({
        url: `/tran/tranCopyrightDemand/${id}`,
        method: 'delete',
    });
}
